import React,{useState, useEffect} from 'react';
import CreatableSelect from 'react-select/creatable';

import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";


export default function Tags({productMatchingGroupId}) {  
  const [options, setOptions] = useState([]); 
  const [selectedOption, setSelectedOption] = useState(null); 
  const [isLoading, setIsLoading] = useState(false);
  const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/group/tag/${productMatchingGroupId}`;
  // const { productShare, productSelect, productView, productLike, productAlert, showAlert } = props;
  const handleChange = (items) => {
    setSelectedOption(items);   

    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/group/tag/update`;
    const postData = {
      pmgId: productMatchingGroupId,
      tags: items
    };
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })     
      .catch((err) => {
        console.log(err);
      });
  };
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await axios(url, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      });
      let selected = result.data.data.options.filter(x=>result.data.data.selectedOptions.includes(x.value))
      // console.log(selected);
      setSelectedOption(selected);
      setOptions(result.data.data.options);
      setIsLoading(false);
    };

    fetchData();
  }, []); 
  return (
    
              <div style={{zIndex:1000, paddingRight:'0px', flexGrow:'1'}} >
                {/* {!isLoading&& */}
                <CreatableSelect
                  value={selectedOption}
                  onChange={handleChange}
                  options={options}
                  isMulti
                  name="tag"
                  placeholder="Product tags"
                />     
              </div>       
     
   
  );
}
