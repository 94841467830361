import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {  useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  productImage: {
    objectFit: "contain", //
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
    // cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgb(0 176 79 / 10%)",
    },
  },
  loading: {
    color: theme.palette.secondary.main,
  },
  badge: {
    top: '16px',
    // marginLeft: '-198px',
    left: '0px',
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    border:'1px solid #DDD',
    color:"#FFF",
    backgroundColor: '#DDD',
  },
}));
export default function CompanyLogo(prop) {
  const classes = useStyles();
  const {company_id} = prop
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector(state => state);
  const [company, setCompany] = useState({});
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      
      // Check local storage first
      const cachedCompany = localStorage.getItem(`company_${company_id}`);
      if (cachedCompany) {
        setCompany(JSON.parse(cachedCompany));
        setIsLoading(false);
        return;
      }

      const apiUrl = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company/${company_id}`;
      try {
        const result = await axios.get(apiUrl,
          {
            headers: {
              Authorization: state.session.token,
            },
          });
        const companyData = result.data.data;
        setCompany(companyData);
        // Cache the company data in local storage
        localStorage.setItem(`company_${company_id}`, JSON.stringify(companyData));
      } catch (e) {
        console.log(e)
      }
      setIsLoading(false);
    };
    fetchDataSource();
  }, [company_id]);
  
 
  return (
      <>
        {isLoading ? (          
          <CircularProgress className={classes.loading} />
        ) : (
             
              <img
                  {...prop}
                  src={
                    company.companyLogoLink
                      ? company.companyLogoLink
                      : `https://bettercart.ca/resources/images/no-picture-available.png`
                  }
                  onError={addDefaultSrc}
                />
          
        )}
      </>
  );
}
