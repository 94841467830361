import React, { useState,useEffect } from "react";
import MuiCardMedia from "@material-ui/core/CardMedia";
import axios from "axios";
import envConfig from "../../bin/env.config";
import DialogImageZoomIn from "../Product/DialogImageZoomIn";
import { useSelector } from "react-redux";

export const CardMediaCustom =((props) => {
  const { company_product_id, ...other } = props;
  const [productImage, setProductImage] = useState({image_url: '', company_id: 0});
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.session.token);
  
  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      const apiUrl = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/product-images-by-cpid`;
      const postData = {
        id: company_product_id,
      };
      try {
        const result = await axios.post(apiUrl, postData,
          {
            headers: {
              Authorization: token,
            },
          });
        setProductImage(result.data.data);        
      } catch (e) {
        console.log(e)
      }
      setIsLoading(false);
    };
    fetchDataSource();
  }, [company_product_id]);
  
  return (
      <>
        {isLoading ? (
          <MuiCardMedia
            {...other}
            component="img"
            image={"/resources/images/image-200-loading.png"}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/resources/images/no-picture-available.png";
            }}
          />
        ) : (
          <>
          <MuiCardMedia
            {...other}
            component="img"
            image={productImage.image_url}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/resources/images/no-picture-available.png";
            }}
          />
           <DialogImageZoomIn imageUrl={productImage.image_url} /> 
          </>
        )}
      </>
  );
});

