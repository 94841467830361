import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { format } from "date-fns";
import axios from "axios";
import envConfig from "../../bin/env.config";
import CircularProgress from '@material-ui/core/CircularProgress';
import VizSensor from "react-visibility-sensor";

import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  tableTd: {
    padding: "0px",
  }, 
}));

export default function ProductPricesInfo({companyProductId, limit=3}) {
  const classes = useStyles();
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.session.token);
 
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/product/get-prices-by-cpid`;
      const postData = {
        limit: limit,
        id: companyProductId,
      };
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: token,
        },
      });
      setDataSource(result.data.data);
      setIsLoading(false);
    };
    fetchData();
  }, [companyProductId]);
  
  return (
       <div>
        {isLoading?(
            <CircularProgress size={16} />
          ):(
          <Table className={classes.table}>
            <TableBody>
              {dataSource &&
                dataSource.map((storeProductPrice,i) => (
                  <TableRow key={`spp_tbl_row_${i}`}>
                    <TableCell className={classes.tableTd} title={storeProductPrice.store_address}>
                      {storeProductPrice.store_name}
                    </TableCell>
                    <TableCell className={classes.tableTd}>
                      ${storeProductPrice.current_price_ea}
                      {/* /Reg:${storeProductPrice.regular_price_ea} */}
                    </TableCell>
                    <TableCell className={classes.tableTd}>
                      {/* {storeProductPrice.edited_at} */}
                       {format(new Date(storeProductPrice.edited_at), "yyyy-MM-dd")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          )}
      </div>
  );
}
