import axios from 'axios';
import envConfig from '../bin/env.config';

import { setProducts } from './pricecomparison';

export const setSearchingStatus = (isSearching = true) => ({
  type: 'SET_SEARCHING',
  isSearching
});

export const setErrorStatus = (errorOccurred = true) => ({
  type: 'SET_ERROR_OCCURRED',
  errorOccurred
});

export const setErrorStatusTodaysDeals = (errorOccurredTodaysDeals = true) => ({
  type: 'SET_ERROR_OCCURRED_TODAYS_DEALS',
  errorOccurredTodaysDeals
});

export const setSearchResult = (products = [], searchTerm,searchCategory) => ({
  type: 'SET_SEARCH_RESULT',
  searchResult: products,
  searchTerm: searchTerm,
  searchCategory:searchCategory
});

export const clearSearchHistory = () => ({
  type: 'CLEAR_SEARCH_HISTORY'
});

export const updateSearchHistory = (product) => ({
  type: 'UPDATE_SEARCH_HISTORY',
  product
});

export const updateTodaysDeals = (product) => ({
  type: 'UPDATE_TODAYS_DEALS',
  product
});

export const makeAllProductsAvailable = (product) => ({
  type: 'MAKE_ALL_PRODUCTS_AVAILABLE'
});

export const setLoadingTodaysDeals = (isLoadingTodaysDeals = true) => ({
  type: 'SET_LOADING_TODAYS_DEALS',
  isLoadingTodaysDeals
});

export const setTodaysDeals = (products = []) => ({
  type: 'SET_TODAYS_DEALS',
  todaysDeals: products
});

export const setLoadProductSpinner = (isLoadingProduct = true) => ({
  type: 'SET_LOAD_PRODUCT_SPINNER',
  isLoadingProduct
});

export const setErrorOccurredLoadingProduct = (errorOccurredLoadingProduct = true) => ({
  type: 'SET_ERROR_OCCURRED_LOADING_PRODUCT',
  errorOccurredLoadingProduct
});

export const setProductBeingViewed = (productBeingViewed) => ({
  type: 'SET_PRODUCT_BEING_VIEWED',
  productBeingViewed
});

// export const searchProduct = (searchTerm, cart = [], latitude, longitude, filteredStores = []) => {

export const searchProduct = (searchTerm, searchCategory) => {
  return (dispatch, getState) => {
    const requestBody = {
      term: searchTerm,
      category:searchCategory,
      latitude: undefined,
      longitude: undefined,
      stores: undefined
    };    
   
    const state = getState();
    const cart = state.cart;
    const latitude = state.location.latitude;
    const longitude = state.location.longitude;
    const filteredStores = state.searchfilters.filteredStores;
    const proximity = state.searchfilters.proximity;
    

    dispatch(setSearchingStatus(true));
    // dispatch(setErrorStatus(false));

    //let url = `${envConfig.API_ROOT}/search/products`;
    // console.log(process.env.REACT_APP_ES);
    // let apiElasticSearch = (process.env.REACT_APP_ES==='true'?'product':'products'); //use api product for ES search
    let apiElasticSearch = 'products'; //use api product for ES search
    let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/search/${apiElasticSearch}`;
    // console.log(url);  
    if (latitude && longitude) {
      // url += `?latitude=${latitude}&longitude=${longitude}`;
      requestBody.latitude = latitude;
      requestBody.longitude = longitude;
    }

    // if (filteredStores) {
    //   for (let store of filteredStores) {
    //     url += `&${store._id}`;
    //   }
    // }

    if (filteredStores && filteredStores.length > 0) {
      // url += '&stores=' + filteredStores.map((store) => store._id).join(','); 
      //requestBody.stores = filteredStores.map((store) => store._id);
      const stores = filteredStores.map((store) => store._id);        
      requestBody.stores = stores;
    }

    requestBody.proximity = proximity;    
    // console.log("#api post no1:"+url);
    // console.log(requestBody)
    axios.post(url, requestBody)
      .then((response) => {
        const data = response.data.data;//;
        const companies = [];
        
        data.sort((a, b) => (a.company.sort > b.company.sort) ? 1 : -1)
        //const selectedStores = this.state.selectedStores.sort((a, b) => (a.companyData.sort > b.companyData.sort) ? 1 : -1);
       
        // console.log(data);
       

        // filter companies with at least 1 match
        for (let companyData of data) {
          // console.log(companyData.company);
         if (companyData.matches.length > 0) {
           companies.push(companyData); 
         }
        }

        dispatch(setSearchResult(companies, searchTerm, searchCategory));

        // const stores = response.data.data;
        // const parsedStores = stores;

        // const parsedStores = [];

        // for (let storeData of stores) {

        //   const store = {
        //     store: storeData.store,
        //     matches: []
        //   };

        //   const products = [];
        //   for (let product of storeData.matches) {

        //     // check if the product is already in the cart
        //     let found = false;

        //     for (let cartProduct of cart) {
        //       if (cartProduct._id === product._id) {
        //         found = true;
        //       }
            
        //     }

        //     if (found) {
        //       product.inTheCart = true;
        //     }

        //     products.push(product);
        //   }

        //   store.matches = products;

        //   parsedStores.push(store);
        // }
        
        // console.log(parsedStores);

        // setTimeout(() => {
          // dispatch(setSearchingStatus(false));
          // dispatch(setSearchResult(parsedStores, searchTerm));
        // }, 3000);

        // dispatch(setSearchResult(groupStoresIntoCompanies(stores), searchTerm));
        
      })
      .catch((err) => {
        // dispatch(setSearchingStatus(false));
        dispatch(setErrorStatus(true));
      });
  };
}

export const fetchFeaturedProducts = (cart = [], latitude, longitude) => {
  return (dispatch, getState) => {
    dispatch(setLoadingTodaysDeals(true));
    // dispatch(setErrorStatusTodaysDeals(false));
     
    const state = getState();
    const filteredStores = state.searchfilters.filteredStores;
    const proximity = state.searchfilters.proximity;
    const requestBody = {     
      proximity:proximity,
      latitude: latitude,
      longitude: longitude,
      stores: undefined
    };   
    //console.log(filteredStores);
    //let url = `${envConfig.API_ROOT}/products/featured?latitude=${latitude}&longitude=${longitude}&proximity=${proximity}`;

    // let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/products/featured?latitude=${latitude}&longitude=${longitude}&proximity=${proximity}`;
    let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/products/featured`;

    if (filteredStores && filteredStores.length > 0) {
      const stores = filteredStores.map((store) => store._id);
      requestBody.stores = stores;
    }
    // console.log("#api get no2:"+url);
    axios.post(url,requestBody)
      .then((response) => {
        const data = response.data.data;
        // const products = [];

        // for (let product of data) {

        //   // check if the product is already in the cart
        //   let found = false;

        //   for (let cartProduct of cart) {
        //     if (cartProduct._id === product._id) {
        //       found = true;
        //     }
          
        //   }

        //   if (found) {
        //     product.inTheCart = true;
        //   }

        //   products.push(product);
        // }

        // setTimeout(() => {
          // dispatch(setLoadingTodaysDeals(false));
          // dispatch(setTodaysDeals(products));
        // }, 3000);
      
        dispatch(setTodaysDeals(data));
      })
      .catch((err) => {
        // dispatch(setLoadingTodaysDeals(false));
        dispatch(setErrorStatusTodaysDeals(true));
      });
  };
}

export const fetchStoreFeaturedProducts = (cart = [], filteredStores, latitude, longitude) => {
  // console.log("fetchStoreFeaturedProducts");
  return (dispatch, getState) => {
    dispatch(setLoadingTodaysDeals(true));
    // dispatch(setErrorStatusTodaysDeals(false));

    const state = getState();
    //const filteredStores = state.searchfilters.filteredStores;
    const proximity = state.searchfilters.proximity;    
    const requestBody = {     
      proximity:proximity,
      latitude: latitude,
      longitude: longitude,
      stores: undefined
    }; 
    //let url = `${envConfig.API_ROOT}/products/featured?latitude=${latitude}&longitude=${longitude}&proximity=${proximity}`;

    // let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/products/featured?latitude=${latitude}&longitude=${longitude}&proximity=${proximity}`;
    let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/products/featured`;

    if (filteredStores && filteredStores.length > 0) {
      const stores = filteredStores.map((store) => store._id);
      // url += `&stores=${stores.join(',')}`;
      requestBody.stores= stores
      //url += `&stores=${filteredStores}`;
    }
    // console.log("#api get no3:"+url);
    axios.post(url,requestBody)
      .then((response) => {
        const data = response.data.data;
        // const products = [];

        // for (let product of data) {

        //   // check if the product is already in the cart
        //   let found = false;

        //   for (let cartProduct of cart) {
        //     if (cartProduct._id === product._id) {
        //       found = true;
        //     }
          
        //   }

        //   if (found) {
        //     product.inTheCart = true;
        //   }

        //   products.push(product);
        // }

        // setTimeout(() => {
          // dispatch(setLoadingTodaysDeals(false));
          // dispatch(setTodaysDeals(products));
        // }, 3000);
      
        dispatch(setTodaysDeals(data));
      })
      .catch((err) => {
        // dispatch(setLoadingTodaysDeals(false));
        dispatch(setErrorStatusTodaysDeals(true));
      });

      
  };
}

export const findProduct = (productID) => {
  return (dispatch, getState) => {
    dispatch(setProductBeingViewed(null));
    dispatch(setLoadProductSpinner(true));
    // dispatch(setErrorOccurredLoadingProduct(false));
    const state = getState();
    const filteredStores = state.searchfilters.filteredStores;    
    
    //console.log(filteredStores);
    let storeIds = '';
    if (filteredStores && filteredStores.length > 0) {
      const stores = filteredStores.map((store) => store._id);
      //storeIds += stores.join(',');
      storeIds += stores[0];
    }
    // console.log(filteredStores);
    // console.log(storeIds);
    //let url = `${envConfig.API_ROOT}/products/featured?latitude=${latitude}&longitude=${longitude}&proximity=${proximity}`;
    
    //const url = `${envConfig.API_ROOT}/products/${productID}`;
    
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/products/${productID}/stores/${storeIds}`;
    // console.log("#api get no4:"+url);
    axios.get(url)
      .then((response) => {
        const data = response.data.data
        data.inTheCart = false;
        
        // setTimeout(() => {
          dispatch(setProducts([data]));;
          dispatch(setProductBeingViewed(data));
          // dispatch(setLoadProductSpinner(false));
        // }, 3000);
      })
      .catch((err) => {
        // dispatch(setLoadProductSpinner(false));
        dispatch(setErrorOccurredLoadingProduct(true));
      });
  };
}

const groupStoresIntoCompanies = (data) => {
  const companies = {};
  const response = [];

  for (let storeData of data) {
    const company = storeData.store.companyData;
    if (companies[company._id]) {
      companies[company._id].stores.push(storeData);
    } else {
      companies[company._id] = {
        company: company,
        stores: [storeData]
      }
    }
  }

  for (let cid in companies) {
    response.push(companies[cid]);
  }

  return response;
}

